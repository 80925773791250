.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    animation: fadeIn 0.3s ease;
  }
  
  .popup-content {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease;
  }
  
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 5px;
    line-height: 1;
    transition: color 0.3s ease;
  }
  
  .close-btn:hover {
    color: #333;
  }
  
  h2 {
    margin: 0 0 10px 0;
    color: #333;
    font-size: 1.5rem;
  }
  
  .upload-description {
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .drop-zone {
    border: 2px dashed #ccc;
    border-radius: 15px;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 1.5rem;
    background: #f8f9fa;
  }
  
  .drop-zone:hover, .drop-zone.active {
    border-color: #11b927;
    background: #f0f9f1;
  }
  
  .drop-zone.has-file {
    border-style: solid;
    background: #e8f5e9;
  }
  
  .upload-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .file-types {
    display: block;
    font-size: 0.85rem;
    color: #666;
    margin-top: 0.5rem;
  }
  
  .file-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .file-name {
    font-weight: 500;
    color: #333;
    word-break: break-all;
  }
  
  .file-size {
    font-size: 0.85rem;
    color: #666;
  }
  
  .upload-btn {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 10px;
    background: #ccc;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    cursor: not-allowed;
    transition: all 0.3s ease;
  }
  
  .upload-btn.active {
    background: #11b927;
    cursor: pointer;
  }
  
  .upload-btn.active:hover {
    background: #0ea522;
    transform: translateY(-1px);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .popup-content {
      width: 80%;
      padding: 1.5rem;
    }
    
    .drop-zone {
      padding: 1.5rem;
    }
  }