.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

}

.nav-brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-brand:hover {
  cursor: pointer;
}

.nav-logo {
  height: 45px;
  width: auto;
}

.brand-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #11b927;
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 6px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-150%);
    transition: transform 0.3s ease;
    opacity: 0;
  }

  .nav-links.active {
    transform: translateY(0);
    opacity: 1;
  }

  .nav-link {
    padding: 0.5rem 0;
    width: 100%;
    text-align: center;
  }

  .bar.active:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }

  .bar.active:nth-child(2) {
    opacity: 0;
  }

  .bar.active:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
}