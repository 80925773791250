.homepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.homepage-hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fadeIn 0.8s ease-in;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;
  width: 100%;

}

.homepage-hero-image {
  width: 650px;
  transition: transform 0.3s ease;
}

.homepage-hero-image:hover {
  transform: translateY(-5px);
}

.homepage-intro-text {
  margin-bottom: 2.5rem;
  padding: 0 1rem;
}

.homepage-intro-text h1 {
  font-size: 2em;
}

.homepage-intro-text p {
  font-size: 1.1rem;
  color: #4a5568;
  line-height: 1.6;
  max-width: 600px;
  margin: 10px auto;
}

.homepage-get-started-btn {
  background-color: #29CC57;
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.15s ease;
  position: relative;
  border-bottom: 4px solid #0a8f1c;
  transform: translateY(-2px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 35px; */
}

.homepage-get-started-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%);
  transform: skewX(-25deg);
  animation: shine 3s infinite;
}

.homepage-get-started-btn:hover {
  background-color: #22b64c;
  transform: translateY(0);
  border-bottom-width: 4px;
}

.homepage-upload-icon {
  font-size: 1.2rem;
  margin-left: 7px;
}



.homepage-purpose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 2px solid #eaeaea;
  box-sizing: border-box;
  margin-top: auto;

}

.homepage-purpose-icons {
  display: flex;
  justify-content: space-around;
  width: 70%;
  box-sizing: border-box;
  padding: 10px 0;


}

.homepage-purpose-icons>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.homepage-purpose-icons>div>p {
  font-weight: bold;
}

.homepage-purpose-icons-img {
  width: 30px;
}

.homepage-social-proof {
  height: 350px;
  width: 100%;
  gap: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100vw;
}

.homepage-social-proof h2 {

  font-size: 3rem;
  font-weight: 600;
  color: white;
}

.homepage-social-proof-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.homepage-social-proof-stars {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.homepage-social-proof-stars-txt {
  font-size: 18px;
  color: white;
  margin: 0;
}

.homepage-social-proof-stars-svg {
  width: 200px;
}

.homepage-social-proof-news {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.homepage-social-proof-news img {
  width: 180px;
}

.homepage-social-proof-news {
  display: flex;
  flex-direction: column;
}

.homepage-social-proof-editors {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.homepage-social-proof-editors img,
.homepage-social-proof-editors svg {
  width: 150px;
}

.homepage-features-section-container {
  display: flex;
  flex-direction: column;


}

.homepage-features-section:nth-of-type(2) {
  flex-direction: row-reverse;
}

.homepage-features-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  box-shadow:
    inset 0 20px 20px -20px rgba(0, 0, 0, 0.1),
    inset 0 -20px 20px -20px rgba(0, 0, 0, 0.1);
}

.homepage-features-section img {
  width: 50vw;
}

.homepage-features-section h3 {
  font-size: 40px;
}

.homepage-features-section p {
  font-size: 25px;
}

.homepage-footer {
  width: 100%;
  background-color: #000;
  color: white;
  padding: 4rem 2rem 2rem;
  margin-top: auto;
  box-sizing: border-box;
}

.homepage-footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.homepage-footer-brand h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.homepage-footer-brand p {
  color: #666;
  font-size: 0.9rem;
}

.homepage-footer-links {
  display: flex;
  gap: 2rem;
}

.homepage-footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.homepage-footer-links a:hover {
  color: #78dbf1;
}

.homepage-footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  border-top: 1px solid #222;
  margin-top: 3rem;
  padding-top: 2rem;
  text-align: center;
  color: #666;
  font-size: 0.85rem;
}

@media (max-width: 1201px) {

  .homepage-purpose-icons {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .homepage-features-section-container {
    justify-content: center;
    align-items: center;
  }

  .homepage-features-section-desc h3 {
    text-align: center;
  }

  .homepage-features-section-desc p {
    text-align: center;
  }

  .homepage-features-section {
    flex-direction: column;
    padding: 100px 20px;
  }

  .homepage-features-section:nth-of-type(2) {
    flex-direction: column;
  }

  .homepage-hero-image {
    width: 600px;
  }

  .homepage-purpose-icons {
    width: 100%;
  }

  .homepage-social-proof {
    height: fit-content;
    padding: 50px 0;
  }

  .homepage-social-proof-elements {
    flex-direction: column;
  }

  .homepage-social-proof h2 {
    font-size: 2rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .homepage-hero-image {
    width: 80%;
  }

  .homepage-footer-content {
    flex-direction: column;
    text-align: center;
  }

  .homepage-footer-links {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem 2rem;
  }
}

@keyframes shine {
  0% {
    left: -75%;
  }

  20% {
    left: 125%;
  }

  100% {
    left: 125%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .homepage-intro-text h1 {
    font-size: 1.7rem;
  }

  .homepage-intro-text p {
    font-size: 1rem;
  }

  .homepage-get-started-btn {
    padding: 0.8rem 2rem;
  }
}