/* Analysis card container */
.analysis-card {
  width: 100%;
  max-width: 64rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}


.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid #edf2f7;
}

.card-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}


.card-content {
  padding: 1.5rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.analysis-text {
  line-height: 2;
  font-size: 1rem;
  color: #2d3748;
}


.sentence-group {
  position: relative;
  display: inline;
  cursor: pointer;
  padding: 4px 0px;
  border: 2px solid rgba(203, 213, 224, 0.4);
  border-radius: 4px;
  transition: all 0.2s ease;
  margin: 20px 0px;
}

.sentence-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: rgba(203, 213, 224, 0.8);
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background-color: #2d3748;
  color: white;
  font-size: 0.75rem;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  margin-bottom: 8px;
  /* Increased to account for the lift effect */
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tooltip-arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 4px solid transparent;
  border-top-color: #2d3748;
}

.sentence-group:hover .tooltip {
  visibility: visible;
  opacity: 1;
}


.summary-box {
display: flex;
justify-content: center;
align-items: center;
  padding: 1rem;
  background-color: #f7fafc;
  border-radius: 8px;
}

.summary-box p {
  font-size: 20px;
  color: #4a5568;
  margin: 0;
}


@media (max-width: 768px) {
  .card-header {
    padding: 1rem;
  }

  .card-content {
    padding: 1rem;
  }

  .card-header h2 {
    font-size: 1.25rem;
  }

  .analysis-text {
    font-size: 0.9rem;
  }
}